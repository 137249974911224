<style scoped>
  @media print {
    .no-print {
      display: none !important;
    }
  }

  .floating-button-wrapper {
    position: fixed;
    bottom: 30px;
    right: 30px;
    cursor: pointer;
  }

  .floating-button {
    border-radius: 50%;
  }
</style>

<template>
  <div class="floating-button-wrapper">
    <a
      slot="trigger"
      class="button floating-button is-success is-large"
      :title="$t('common.add')"
      @click="addContact"
    >
      <b-icon icon="plus" />
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { keys } from 'lodash'
import ContactForm from '@/components/Contacts/Form'

export default {
  async created() {
    await this.$store.dispatch('users/fetchAndAdd', { limit: 0 })
  },
  methods: {
    keys,
    addContact () {
      const id = new Date().toString()
      this.$store.dispatch('currentDoc/set', {
        field: `team.${id}`,
        value: {
          id,
          name: '',
          email: '',
          phone: ''
        }
      })

      this.$buefy.modal.open({
        parent: this,
        component: ContactForm,
        hasModalCard: true,
        width: 400,
        height: 400,
        props: {
          id,
          options: this.$store.getters["users/items"],
          collectionPath: 'team',
          header: this.$t('failures.team_member')
        }
      })
    }
  }
}
</script>
